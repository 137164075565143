import { Box, Checkbox, Divider, Grid, TextField, Typography } from "@material-ui/core";
import { convertCurrency } from "common/Utils/currency";
import { getFilteredOrders, getVisibleOrders, groupeBySku } from "common/Utils/orders";
import { FbaFee, ItemCosts, Order, ReturnItem, } from "common/types";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import { endOfDay, startOfDay } from "date-fns";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDataDB, getFees, getOrders, getReturnReportData, } from "services/service";
import { getColumns, getDynamicColumnsForExport } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export enum ProfitBreakdown {
    sku = "sku",
    grossRevenue = "grossRevenue",
    expenses = "expenses",
    cogs = "cogs",
    advertisingCost = "advertisingCost",
    refunds = "refunds",
    netProfit = "netProfit",
    netMargin = "netMargin",
}


interface ColumnOrders extends Column {
    accessor?: ProfitBreakdown;
}

function ProductsPerformance() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>(""); // todo
    const [orders, setOrders] = useState<Order[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);
    const [itemCosts, setItemCosts] = useState<ItemCosts>({});

    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getOrders(),
            getFees(),
            getReturnReportData(),
            getDataDB<ItemCosts>("itemCosts"),
        ]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setReturnedItems(val[2])
            setItemCosts(val[3])
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const { t } = useTranslation();
    const currencyContext = useContext(CurrencyContext);
    const currencyConvert = convertCurrency(currencyContext?.currency);

    const filteredDateOrders = getFilteredOrders(orders, startDate, endDate)
    const calculatedOrders = getVisibleOrders(filteredDateOrders, fees);

    const groupedOrdersBySKU = groupeBySku(calculatedOrders);

    const tableData = groupedOrdersBySKU.map(sameSKUorders => {
        const skuMatch = sameSKUorders[0].sku;

        let exwPrice = 0;
        let customs = 0;
        let otherCosts = 0;
        let transportationCosts = 0;

        const matchElement = itemCosts?.[skuMatch];
        const returnedItemsSKU = returnedItems.filter(el => el.sku === skuMatch).reduce((total, item) => total + item.quantity, 0);

        if (matchElement) {
            exwPrice = matchElement.exwPrice;
            customs = matchElement.customs;
            otherCosts = matchElement.otherCosts;
            transportationCosts = matchElement.transportationCosts;
        }


        const calculations = sameSKUorders.reduce((acc, order) => {
            const cogs = order.fbaFee + (exwPrice || 0) + (customs || 0) + (transportationCosts || 0) + (otherCosts || 0);
            const expenses = order.fbaFee + order.adsCost;
            acc.expenses += expenses;
            acc.grossRevenue += order.revenue;
            acc.netProfit += order.revenue - expenses;
            acc.sku = skuMatch;
            acc.refunds = returnedItemsSKU * order.fbaFee;
            acc.advertisingCost = order.adsCost;
            acc.cogs = cogs;
            return acc;
        }, {
            sku: "",
            cogs: 0,
            expenses: 0,
            grossRevenue: 0,
            netProfit: 0,
            refunds: 0,
            advertisingCost: 0,
        });


        return calculations;
    }).map(el => ({
        ...el,
        cogs: currencyConvert(el.cogs),
        expenses: currencyConvert(el.expenses),
        grossRevenue: currencyConvert(el.grossRevenue),
        netProfit: currencyConvert(el.netProfit),
        refunds: currencyConvert(el.refunds),
        advertisingCost: currencyConvert(el.advertisingCost),
        netMargin: currencyConvert(el.netProfit / el.grossRevenue * 100),
    }))

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) })

    const { sideBar, actions, profitBreakdown } = TranslationPrefixes;

    const createTableCell = (accessor: ProfitBreakdown) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${profitBreakdown}${accessor}`)
        }
    }

    const columns: Array<ColumnOrders> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />
                )
            },
            header: "checkBox"
        },
        ...Object.values(ProfitBreakdown).map(createTableCell)
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);


    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;
        const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.productsPerformance, values: Object.values(ProfitBreakdown) });
        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `working_dashboard_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    return (
        <CardComponent title={t(`${sideBar}profitBreakdown`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} >
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            onChange={(e) => {
                                setCurrentSearch(e.target.value as string);
                            }}
                            label={t(`${actions}search`)}
                        />
                    </Grid>
                </PeriodSelection>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default ProductsPerformance;
