import { Box, Checkbox, Divider, Grid, TextField, Typography } from "@material-ui/core";
import { convertCurrency } from "common/Utils/currency";
import { getFilteredOrders, getVisibleOrders, groupeBySku, netMarginItemExpenses } from "common/Utils/orders";
import { FbaFee, InventoryReport, Order, ReturnItem, SaleTraffic } from "common/types";
import PeriodSelection from "components/shared/PeriodSelection";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import { endOfDay, startOfDay } from "date-fns";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFees, getInventoryListingData, getOrders, getReturnReportData, getSalesAndTraffic } from "services/service";
import { getColumns, getDynamicColumnsForExport } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";
const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export enum PerformanceTable {
    sku = "sku",
    grossRevenue = "grossRevenue",
    expenses = "expenses",
    netProfit = "netProfit",
    netMargin = "netMargin",
    roi = "roi",
    refunds = "refunds",
    units = "units",
    pageViews = "pageViews",
    sessionUnits = "sessionUnits",
    sessionUnitPercentage = "sessionUnitPercentage",
}

interface ColumnOrders extends Column {
    accessor?: keyof typeof PerformanceTable;
}

interface TableCalculatedData {
    [PerformanceTable.netProfit]: number;
    [PerformanceTable.grossRevenue]: number;
    [PerformanceTable.units]: number;
    [PerformanceTable.expenses]: number;
    [PerformanceTable.roi]: number;
    [PerformanceTable.pageViews]: number;
    [PerformanceTable.sessionUnits]: number;
    [PerformanceTable.sessionUnitPercentage]: number;
    [PerformanceTable.refunds]: number;
}

function ProductsPerformance() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>(""); // todo
    const [salesAndTraffic, setSalesAndTraffic] = useState<SaleTraffic[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [inventory, setInventory] = useState<InventoryReport[]>([]);
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees(), getSalesAndTraffic(), getReturnReportData(), getInventoryListingData()]).then((val) => {
            setOrders(val[0]);
            setFees(val[1]);
            setSalesAndTraffic(val[2])
            setReturnedItems(val[3])
            setInventory(val[4])
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false); // Ensure loading state is reset in case of an error
        });
    }, []);

    const { t } = useTranslation();
    const currencyContext = useContext(CurrencyContext);
    const currencyConvert = convertCurrency(currencyContext.currency);

    const filteredDateOrders = getFilteredOrders(orders, startDate, endDate)
    const calculatedOrders = getVisibleOrders(filteredDateOrders, fees);

    const groupedOrdersBySKU = groupeBySku(calculatedOrders);

    const tableData = groupedOrdersBySKU.map(sameSKUorders => {
        const skuMatch = sameSKUorders[0].sku;
        const salesTrafficItem = salesAndTraffic?.find(el => el.sku === skuMatch);
        const inventoryItem = inventory.find(el => el.sku === sameSKUorders[0].sku)
        const { pageViews, sessions, sessionPercentage } = salesTrafficItem?.trafficByAsin || {}

        const countedSessions = sessions ?? 0;
        const countedPageViews = pageViews ?? 0;
        const combineAllCalculations = sameSKUorders.reduce((acc, order) => {
            const expenses = order.fbaFee + order.adsCost;
            acc[PerformanceTable.expenses] += currencyConvert(expenses);
            acc[PerformanceTable.grossRevenue] += currencyConvert(order.revenue);
            acc[PerformanceTable.units] += inventoryItem?.quantity ?? 0;
            acc[PerformanceTable.netProfit] += currencyConvert(order.revenue - expenses);
            acc[PerformanceTable.pageViews] = countedPageViews;
            acc[PerformanceTable.sessionUnits] = countedSessions;
            acc[PerformanceTable.sessionUnitPercentage] = countedSessions === 0 || countedPageViews === 0 ? 0 : (countedSessions / countedPageViews) * 100;
            return acc;
        }, {
            expenses: 0,
            quantity: 0,
            netProfit: 0,
            roi: 0,
            pageViews: 0,
            sessionUnits: 0,
            sessionUnitPercentage: 0,
            units: 0,
            grossRevenue: 0,
            refunds: 0
        } as TableCalculatedData);

        for (const key in combineAllCalculations) {
            if (typeof combineAllCalculations[key as keyof TableCalculatedData] === 'number') {
                combineAllCalculations[key as keyof TableCalculatedData] = parseFloat((combineAllCalculations[key as keyof TableCalculatedData]).toFixed(2));
            }
        }

        const netMargin = +netMarginItemExpenses({ expenses: combineAllCalculations[PerformanceTable.expenses], totalRevenue: combineAllCalculations[PerformanceTable.grossRevenue] });
        return {
            sku: sameSKUorders[0].sku,
            asin: sameSKUorders[0].asin,
            netMargin: currencyConvert(netMargin),
            // roi: "?",
            ...combineAllCalculations
        };
    });


    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) })

    const { sideBar, actions, productsPerformance } = TranslationPrefixes;

    const createTableCell = (accessor: PerformanceTable) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${productsPerformance}${accessor}`)
        }
    }

    const columns: Array<ColumnOrders> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />
                )
            },
            header: "checkBox"
        },
        ...Object.values(PerformanceTable).map(createTableCell)
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);
    const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.productsPerformance, values: PerformanceTable });

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;

        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `working_dashboard_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
            />
        );
    }

    return (
        <CardComponent title={t(`${sideBar}products`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} >
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            onChange={(e) => {
                                setCurrentSearch(e.target.value as string);
                            }}
                            label={t(`${actions}search`)}
                        />
                    </Grid>
                </PeriodSelection>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {
                    tableData.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={tableData}
                                headers={csvColumns}
                                title={t(`${sideBar}products`)}
                            />
                        </div>
                    )
                }
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default ProductsPerformance;
