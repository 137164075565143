import { CurrencyRate } from "context/currency";
import { Currency } from "interfaces/currency";

export function convertCurrency(args: CurrencyRate): (valueToConvert: number) => number {
    const { rate, value } = args;

    return (valueToConvert?: number) => {

        if (value === Currency.USD) {
            return +(valueToConvert || 0).toFixed(2);
        }

        return +((valueToConvert || 0) * rate).toFixed(2);
    }

}